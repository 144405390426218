.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table tr td{
  text-align: left;
}
.gradient-header .main-header{
  background:linear-gradient( 310.6deg, #003679 6.8%, #243d92 57.8% );
	border-bottom:1px solid transparent !important;
}
.color-leftmenu .main-sidebar-sticky{
	background: #003679;
	border-right: 1px solid transparent;
}

@media (min-width: 992px) {
	.main-sidebar {
	
		z-index: 500;
	
	}
}

.orange{
  
}

.flag-row{
  background-color: #8760fb  !important;
  color: #000000 !important;
}

.warn-row{
  background-color:#fbdec2  !important;
  color: #000000 !important;
}

.flag-row a{
  color:#FFFFFF
}
.small-caps{
  font-size: 11px !important;
}
.text-white{
  color: #FFFFFF;
}

.touch-close-menu{
  float: left;
  position:absolute;
  left:5px;
  display: none;
}
.custom-menu-icon{
  font-size: 25px;
  color: #3f4651;
  cursor: pointer;
}

.pointer{
  cursor: pointer;
}

@media (max-width: 1000px) {
	.touch-close-menu {
		display: inline;

	}
}

a:hover{
  text-decoration: none;
}

/* ----- NEW CSS ------ */

.table-header {
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  margin: 7px 0 7px 0;
}

.card .card-title {
  /* margin-bottom: 1rem !important; */
  font-size: 0.97rem !important;
  font-weight: 500;
}

.card .card-description {
  color: rgb(118, 131, 143) !important;
  font-size: 0.79rem !important;
  line-height: 1.3rem !important;
}

.see-all-btn {
  margin: 0 !important;
}

/*  ----- 5.4 Image    ----- */

/* .main-img-user {
	width: 37px !important;
	height: 37px !important;
	border-radius: 7% !important;
} */
.main-img-user img {
  border-radius: 15%;
}

.btn {
  border-radius: 5px !important;
}

.card {
  border-radius: 7px !important;
}

.card-body {
  padding: 1.5rem 1.7rem !important;
}

@media (min-width: 992px) {
  .main-sidebar.side-menu {
    /* width: 250px !important; */
    box-shadow: 7px 0 60px rgba(0, 0, 0, 0.05) !important;
  }
}

.main-sidebar-body {
  /* padding: 2px 1.5rem 1.5rem !important; */
  border: none !important;
}

.main-sidebar-body .nav-label {
  font-size: 0.6rem !important;
  margin: 0.75rem 0 !important;
  font-weight: bold !important;
  color: #243d92 !important;
}

.main-sidebar-body .nav-link {
  height: 2.5rem;
  white-space: nowrap;
  /* margin: 0.1rem 0.5rem; */
}

.main-sidebar-sticky {
  border: none !important;
}

/* form data */

.form-control {
  border-radius: 5px !important;
}




.table.table-ellipsis tbody td {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
  }
  
  .table.table-ellipsis tbody td:hover {
  text-overflow: clip;
  white-space: normal;
  word-break: break-all;
  cursor: pointer;
  }


  .link, .page-link, .nav-link{
    cursor: pointer;
  }

  .link{
    color: #8760fb !important;
  }

  .see-all-btn {
    margin: 0 !important;
    float: right;
  }


  /*SCROLL BARS**************/
  .main-sidebar-body{
    height: 85% !important;
    overflow-y: auto;
  }


  * {
    scrollbar-width: thin;
    scrollbar-color: #ffffff #ffffff;
  }
  
  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 12px;
  }
  *::-webkit-scrollbar-track {
    background: #FFFFFF;
  }
  *::-webkit-scrollbar-thumb {
    background-color: #FFFFFF;
    border-radius: 20px;
    border: 3px solid #FFFFFF;
  }


  /*ENDSSCROLL BARS**************/

  /******HIDDING NUMBER INPUT ARROW CONTROLS *******/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/******END OF HIDDING NUMBER INPUT ARROW CONTROLS *******/

.table thead th {
  font-weight: 700;
  font-size: 0.7rem;
}

.hr-table {
  color: #fff;
}

.hr-table th,
.hr-table td {
  padding: 1.25rem 0.9375rem;
  vertical-align: top;
  border-top: 1px solid #f3f3f328;
}

.table-hovered tbody tr:hover {
  color: #212529 !important;
  background-color: #eaeaf149 !important;
}

.user-table {
  height: 300px !important;
}


.divider {
  display: inline-block;
  width: 0;
  height: 5em;
  border-left: 1px solid #e1e6f1;
  /* border-right: 1px solid #e1e6f1; */
}

.hot-tr{
  cursor: pointer;
}

.hot-tr:hover{
  background-color: #8760fb;
  color:#FFFFFF
}

.hot-tr:hover a{
  color:#FFFFFF;
  font-weight: bold;
}
.primary{
  color: #8760fb !important;
}
.tag{
  cursor: pointer;
}


.pinned-card {
  color:#334151 !important;
}

.pinned-card:hover {
  color: #7e5ec4 !important;
  border-bottom: 0.1px solid #7e5ec4 !important;
  transition: 0.3s;
  cursor: pointer;
}

.pinned-row:hover,
.card-text-dark:hover {
  color:#7e5ec4 !important;
  transition: 0.3s;
  cursor: pointer;
}

.badge{
  font-size: 10px !important;
}

.flag-row .link{
  color:#FFFFFF !important;
  font-weight: bold;
}